import React from "react";
import styled from "styled-components";
import { breakpoint } from "styled-components-breakpoint";
import {Link} from "gatsby";

import "../styles/fonts.css"
import "../styles/global.css"
import "../styles/datoCMS.css"

import HamburgerButton from "./../components/hamburgerbutton";

const Container = styled.div`
    display: flex;
    flex-direction: row;
`

const PageContainer = styled.div`
    flex: 1;
`

const StaticBlock = styled.div`
        display:none;
        flex-shrink: 0;
    ${breakpoint("tablet") `
        display: block;
        width: 300px;
        height: 100vh;
    `}
    ${breakpoint("desktop") `
        width: 450px;
    `}
`

const NavContainer = styled.div`
        width: 100vw;
        height: 100vh;
        position: fixed;
        transition: transform .5s;
    
    ${breakpoint("mobile")`
        transform: translateX(-100%);
    `}

    ${breakpoint("tablet") `
        transform: translateX(0);
        width: 300px;
        position: fixed;
        height: 100vh;
    `}
    ${breakpoint("desktop") `
        width: 450px;
    `}

    overflow-y: scroll;
    background: #577590;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;

    scrollbar-width: thin;
    scrollbar-color: #41607C  #577590;

    &::-webkit-scrollbar {
        width: 8px;
    }
  
    &::-webkit-scrollbar-track {
        background: #577590;
    }
  
    &::-webkit-scrollbar-thumb {
        background-color: #41607C;
        border-radius: 20px;
        border: 1px solid #41607C;
    }
`

const Header = styled.header`
        font-size: 32px;
        
    ${breakpoint("tablet") `
        font-size: 36px;
    `}
    ${breakpoint("desktop") `
        font-size: 40px;
    `}

    margin: 8% 5%;
    text-align: center;
    color: #ffffff;
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
`

const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 20px;

    & a{
        text-decoration: none
    }
`

const NavTile = styled.div`
    margin: 8px;
    justify-self: stretch;
    text-align: center;

    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    cursor: pointer;

    &:after{
            content: "";
            position: relative;
            top: 0.1em;
            display: block;
            width: 0%;
            height: 4px;
            border-radius: 2px;
            background-color: #FF7171;
            transition: width .75s ease;
        }

    &:hover{
        color: #ddd;
        transition: color .2s;
    }

    &:hover:after{
        width: 100%;
    }
`

const NavFooter = styled.footer`
    justify-self: end;
    flex:1;
    width: 100%;

    color: #fafafa;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    ${breakpoint("tablet") `
        font-size: 11px;
    `}
    ${breakpoint("desktop") `
        font-size: 14px;
    `}

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`

const Author = styled.span`
    margin: 4px;

    & a{
        color: #fff;
        text-decoration: none;
    }
`

const Policy = styled.span`
    margin: 4px;

    & a{
        color: #fff;
        text-decoration: none;
    }
`

const openNav = () => {
    document.getElementById("aside-nav").classList.add("open");
    document.getElementById("hamburger-button").classList.add("hide");
}

const hideNav = () => {
    document.getElementById("aside-nav").classList.remove("open");
    document.getElementById("hamburger-button").classList.remove("hide");
}

const Layout = (props) =>{
    return(
        <Container>
            <NavContainer id="aside-nav">
                <Header>Miejsce pamięci dzieci utraconych w{'\u00A0'}Dębicy</Header>
                <Nav onClick={hideNav}>
                    <Link to="/"><NavTile>Strona główna</NavTile></Link>
                    <Link to="/pomoc/"><NavTile>Potrzebuję pomocy</NavTile></Link>
                    <Link to="/formularz/"><NavTile>Formularz zgłoszeń</NavTile></Link>
                    <Link to="/ksiega-pamieci/"><NavTile>Księga pamięci</NavTile></Link>
                    <Link to="/o-pochowku/"><NavTile>O pochówku</NavTile></Link>
                    <Link to="/o-pomniku/"><NavTile>O pomniku</NavTile></Link>
                    <Link to="/kontakt/"><NavTile>Kontakt</NavTile></Link>
                </Nav>
                <NavFooter>
                    <Author>Created by <a href="mailto: kontakt.pawel.czernecki@gmail.com">Paweł Czernecki</a> © {new Date().getFullYear()}</Author>
                    <Policy></Policy>
                </NavFooter>
            </NavContainer>

            <StaticBlock></StaticBlock>

            <HamburgerButton show handle={openNav}/>


            <PageContainer>
                {props.children}
            </PageContainer>
        </Container>
    )
}

export default Layout;